import React, { Component } from 'react';
import Headroom from 'react-headroom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Grid,
  Row,
  Col,
  css,
  styled,
  /*thd,*/ withTheme,
  up,
  down,
} from '@smooth-ui/core-sc';
import HeaderLogo from 'theme/hp-logo-@2x.png';
import { Lock } from 'styled-icons/boxicons-solid/Lock';

const CustomHeadroom = styled(Headroom)`
  .headroom {
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
  .headroom--unfixed {
    position: relative;
    transform: translateY(0);
  }
  .headroom--scrolled {
    transition: transform 200ms ease-in-out;
  }
  .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
  }
  .headroom--pinned {
    position: fixed;
    transform: translateY(0%);
  }
`;

const HeaderWrapper = styled.div`
  background-image: linear-gradient(to right, #0c1e33, #2b415a);
  padding-top: 10px;
  width: 100%;
  ${up(
    'lg',
    css`
      height: 210px;
    `
  )};
`;

const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  height: 157px;
  justify-content: start;
  img {
    height: 200px;
    height: auto;
    width: 133px;
    padding-top: 40px;
  }

  ${down(
    'lg',
    css`
      padding-left: 5px;
      height: 109px;
      width: 85px;
      img {
        width: 80px;
        padding-top: 5px;
      }
    `
  )};
`;

const HeaderRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 5px;
  width: 100%;

  ${up(
    'lg',
    css`
      flex-direction: column;
    `
  )}
`;

const LogoutContainer = styled.div`
  align-items: right;
  display: flex;
  justify-content: flex-end;

  a {
    font-size: 18px;
    font-weight: normal;
    color: #fff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ${down(
    'lg',
    css`
      display: none;
    `
  )}
`;

const IconLock = styled(Lock)`
  color: #fff;
  height: 18px;
  width: auto;
`;

class Header extends Component {
  render() {
    return (
      <header className="site-header">
        {/* Leave the above <header /> here, necessary to contain Headroom */}
        {/* Otherwise Headroom will wrap all sibling elements */}
        <CustomHeadroom disableInlineStyles downTolerance={10}>
          <HeaderWrapper className="header-wrapper">
            <Grid>
              <Row>
                <Col xs={12} lg={6}>
                  <LogoContainer>
                    <Link to="/">
                      <img src={HeaderLogo} alt="HP Commercial Club" />
                    </Link>
                  </LogoContainer>
                </Col>
                <Col xs={12} lg={6}>
                  <LogoutContainer>
                    {this.props.showLogoutInMenu && (
                      <Link to="/logout">
                        <IconLock /> Logout
                      </Link>
                    )}
                  </LogoutContainer>
                </Col>
              </Row>
              <Row>
                <HeaderRightContainer>
                  {this.props.children}
                </HeaderRightContainer>
              </Row>
            </Grid>
          </HeaderWrapper>
        </CustomHeadroom>
      </header>
    );
  }
}

Header.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.object,
  showLogoutInMenu: PropTypes.bool,
};

Header.defaultProps = {
  children: undefined,
  theme: undefined,
  showLogoutInMenu: undefined,
};

export default withTheme(Header);
